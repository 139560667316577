import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Contact from '../components/Contact'
import Menu from '../components/Menu'
import Media from '../utils/style-utils'

const Card = styled.div`
  border: 1px solid #ddd;
  margin: 30px 20px;
  width: 291px;
  height: 340px;
  text-align: center;
`

const Img = styled.div`
  background-image: url("${props => props.url}");
  background-size: cover;
  background-position: center;
  width: 290px;
  height: 290px;
`

const ImgTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  font-size: 14px;
  font-weight: 700;

  * {
    margin: 0;
  }
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 1000px;
  margin: 100px auto;

  ${Media.handheld`
    width: 100%;
    align-items: center;
    justify-content: center;
  `}

  ${Media.tablet`
    width: 100%;
    align-items: center;
    justify-content: center;
  `}
`

// const Hero = styled.div`
//   width: 100%;
//   background-color: pink;
//   height: 100px;
//   margin-bottom: 50px;
// `

const SecondPage = ({ data }) => (
  <Layout>
    <SEO title='Projects' pathname='/projects/' />
    <Menu />
    <h1 style={{ textAlign: 'center', marginTop: '50px' }}>Projects</h1>
    <Grid>
      {data.allContentfulGallery.nodes.map(node => (
        <Card key={node.id}>
          <Img url={`https:${node.photo.file.url}`} />
          <ImgTitle>{node.title}</ImgTitle>
        </Card>
      ))}
    </Grid>
    <Contact />
  </Layout>
)

SecondPage.propTypes = {
  data: PropTypes.shape({}).isRequired
}

export default SecondPage

export const pageQuery = graphql`
  query {
    allContentfulGallery(
      sort: { order: DESC, fields: createdAt }
      filter: { node_locale: { eq: "en-US" } }
    ) {
      nodes {
        id
        title
        photo {
          file {
            url
          }
          title
        }
        node_locale
      }
    }
  }
`
